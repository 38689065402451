import React from "react";

const ConnectNow = () => {
  return (
    <div className="px-16 md:px-6 py-3  bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[8px] transition-all duration-150 ease-linear hover:scale-105 cursor-pointer">
      Connect Now
    </div>
  );
};
export default ConnectNow;
