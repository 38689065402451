import React, { useEffect } from "react";
import animation from "../common/animation";
import styles from "./Faq.module.css";

import StepOne from "../assests/step/step1.svg";
import StepTwo from "../assests/step/step2.svg";
import StepThree from "../assests/step/step3.svg";
import StepFour from "../assests/step/step4.svg";

const data = [
  {
    step: "Step 1",
    imageUrl: StepOne,
    text: "Complete our Application Form",
  },
  {
    step: "Step 2",
    imageUrl: StepTwo,
    text: "Verify You are an Accredited Investor",
  },
  {
    step: "Step 3",
    imageUrl: StepThree,
    text: "Review & Sign Investment Docs",
  },
  {
    step: "Step 4",
    imageUrl: StepFour,
    text: "Wire in Your Funds",
  },
];
const GetStarted = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div className={` ${styles.getStartedBackground} py-24 md:px-[130px]`}>
      <div className=" flex flex-row justify-center text-white text-[32px] leading-[30px] font-rubik font-bold">
        How to Get Started
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-10 items-center py-16">
        {data.map((ele, i) => {
          return (
            <div
              key={i}
              className=" w-[100%] space-x-12 flex flex-col justify-center items-center"
            >
              <div className="w-20 h-20 bg-[#ffffff]/10 ml-[56px] p-5 rounded-[50%] flex flex-row justify-center items-center lg:justify-start ">
                <img src={ele.imageUrl} alt="Tab" />
              </div>
              <div className="text-[#FFC907] flex flex-row mt-8 items-center">
                {ele.step}
              </div>
              <div className="text-[#ffffff]/90 text-center mt-4 items-left">
                {ele.text}
              </div>
            </div>
          );
        })}
      </div>

      <div className=" flex flex-row justify-center mt-6">
        <a href="https://drive.google.com/file/d/1jN8eCBCDaB5bHLX6eVFxa9LFGv1iOGmv/view"  target="_blank" className="py-4 px-12 bg-[#FFC907] text-[#000000]/80 text-base font-rubik font-bold rounded-lg">
          Apply to Start Investing
        </a>
      </div>
      <div className=" flex flex-row justify-center mt-2">
        <div className="text-base text-white ">
          Still have questions?{" "}
          <a
            className="text-[#FFC907] border-b border-[#FFC907]"
            href="https://cal.com/secondstreet"
            target="_blank"
            rel="noreferrer noopener"
          >
            Book a Call Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
