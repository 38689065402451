import React, { useEffect } from "react";
import styles from "./OurTeam.modlue.css";
import animation from "../../common/animation";

import Satyam from "../../assests/OurTeam/Satyam.jpeg";
import Abhi from "../../assests/OurTeam/Abhi.webp";
import Patrik from "../../assests/OurTeam/jaiDeep.jpg";
import { FaLinkedin } from "react-icons/fa6";
import EnsurityFlexibility from "../../common/EnsurityFlexibility";

const data = [
  {
    name: "Satyam Bhasin",
    designation: "MD",
    imgurl: Satyam,
    tag: "John Carter",
  },
  {
    name: "Jaideep Singh",
    designation: "Fund Manager",
    imgurl: Patrik,
    tag: "Sophie Moore",
  },
  {
    name: "Abhimanyu Kucheria",
    designation: "Fund Manager",
    imgurl: Abhi,
    tag: "Abhimanyu Kucheria",
  },
];
const OurTeam = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <>
    {/* <EnsurityFlexibility/> */}
    <div className="pt-12 pb-[30px] leading-[34px] md:leading-[44px] md:py-20 px-4 md:px-10 lg:px-12 xl:px-32" id="our-team">

      <div className="hiddenAnimation text-[#211f54] text-[30px] md:text-[38px] md:leading-[62px] text-center font-bold font-sans">
        {" "}
        Meet our team members
      </div>
      {/* <div className="hiddenAnimation text-center text-base md:text-lg text-[#000000] mt-2">
        Lorem ipsum dolor sit amet consectetur adipiscing elit volutpat gravida{" "}
        <br />
        malesuada quam commodo id integer nam.
      </div> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:place-items-center gap-y-6 md:grid-cols-3 md:gap-6 mt-12">
        {data.map((ele, i) => {
          return (
            <div
              className={`hiddenAnimation max-w-[400px] h-[450px] sm:h-full sm:w-full relative overflow-hidden rounded-lg shadow-lg cursor-pointer`}
              key={i}
            >
              <div className="max-w-[400px] h-[450px] sm:h-full sm:w-full">
              <img
                src={ele.imgurl}
                alt={ele.tag}
                className="object-cover scale-[1.05] w-full h-full  cursor-pointer "
                // style={{
                //   transform:
                //     "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                //   transformStyle: "preserve-3d",
                // }}
              />
              </div>
              <div
                className={`${styles.overlay} absolute top-0 left-0 max-w-[400px] h-[450px] sm:h-full sm:w-full bg-black opacity-30 transition-opacity duration-300`}
              ></div>
              <div
                className={`${styles.text}  h-[100px] w-full px-4 md:px-6 absolute bottom-2 md:bottom-2  text-white flex flex-col justify-end opacity-100 transition-opacity duration-300`}
              >
                <h2 className="text-[18px] xl:text-2xl font-bold font-sans">
                  {ele.name}
                </h2>
                <p className="text-sm xl:text-lg md:font-bold">
                  {ele.designation}
                </p>

                <div className="">
                  <a
                    target="_blank"
                    href={`https://www.linkedin.com/company/secondstreet-fund/`}
                    className="flex items-center space-x-1.5 "
                    rel="noreferrer"
                  >
                    <FaLinkedin color="#0077b5" className="h-6 w-6 " />
                    <span className="text-[14px] font-semibold text-white">
                      @{ele.name.toLowerCase().replace(" ", "")}
                    </span>
                  </a>
                </div>

              </div>
            </div>
          );
        })}
      </div>
    </div>
    </>
  );
};

export default OurTeam;
