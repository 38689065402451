import React, { useEffect } from "react";
import animation from "../../common/animation";

import India from "../../assests/india.jpeg";
import Usa from "../../assests/usa.jpeg";

const data = [
  {
    heading: "Alpha 50",
    link: "/alpha-50",
    desc: "Momentum Strategy to beat Nifty 50 with its own constituents.",
    country: India,
    tag: "IND",
  },
  {
    heading: "Alpha 100",
    link: "/alpha-100",
    desc: "Momentum Strategy to beat Nifty 100 with its own constituents.",
    country: India,
    tag: "IND",
  },
  {
    heading: "Alpha 200",
    link: "/alpha-200",
    desc: "Momentum Strategy to beat Nifty 200 with its own constituents.",
    country: India,
    tag: "IND",
  },
  {
    heading: "Alpha 500",
    link: "/alpha-500",
    desc: "Momentum Strategy to beat Nifty 500 with its own constituents.",
    country: India,
    tag: "IND",
  },
  {
    heading: "India Options",
    link: "/india-options",
    desc: "Market Agnostic Option buying for high absolute returns, with superior risk adjusted returns.",
    country: India,
    tag: "IND",
  },
  {
    heading: "Alpha S&P",
    link: "/alpha-s&p",
    desc: "Momentum Strategy to beat S&P 500 with its own constituents.",
    country: Usa,
    tag: "USA",
  },
  {
    heading: "Alpha NDX",
    link: "/alpha-ndx",
    desc: "Momentum Strategy to beat Nasdaq 100 with its own constituents.",
    country: Usa,
    tag: "USA",
  },
  {
    heading: "USA Options",
    link: "/usa-options",
    desc: "Market Agnostic Option buying for high absolute returns, with superior risk adjusted returns.",
    country: Usa,
    tag: "USA",
  },
];

const Strategies = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);

  return (
    <div  className="box bg-[#243444] py-8 md:py-20 px-4 md:px-36" id="strategies">
      <div className="hiddenAnimation">
        <div className="text-[34px] leading-[42px] md:text-6xl font-medium text-center font-sans text-white">
          Our Investment Strategies
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-12">
          {data.map((ele, i) => (
            <a
              className="relative bg-[#192633] rounded-lg px-8 py-12 hover:scale-105 transition-all duration-150 ease-linear"
              key={i}
              href={ele.link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {ele.heading === "USA Options" && (
                <p className="z-10 absolute top-[-5px] font-medium left-[-5px] bg-[#ffc907] text-[#000000] rounded-br-[25px]   px-[10px] py-[2px] h-fit w-fit text-[10px]">
                  Coming soon
                </p>
              )}
              <div className="text-xs rounded-tr-lg pl-2 pb-[4px] bg-[#141e29] pt-[2px] pr-[2px] absolute right-0 top-0">
                <div className="flex flex-row  items-center">
                  <img
                    src={ele.country}
                    alt={ele.heading}
                    className="w-[16px] h-[10px]"
                  />
                  <span className="text-white text-[10] font-rubik pr-1 pl-2">{ele.tag}</span>
                </div>
              </div>
              <div className="text-[#c2cadd] text-left text-2xl font-rubik">
                {ele.heading}
              </div>
              <div className="text-[#c2cadd]/70 text-[14px] text-left mt-4 font-rubik">
                {ele.desc}
              </div>
            </a>
          ))}
        </div>

        <div className="hiddenAnimation flex flex-row justify-center mt-16">
          <div className="px-6 md:px-12 py-3 bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[8px] transition-all duration-150 ease-linear hover:scale-105 cursor-pointer">
            Connect Now
          </div>
        </div>
      </div>
    </div>
  );
};

export default Strategies;
