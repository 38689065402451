import React, { useEffect } from "react";
import animation from "../../common/animation";
import PhilisophyPicture from "../../assests/Pholosphy.jpeg";
import ConnectNow from "../../common/ConnectNowButton";
import First from "../../assests/WhyUs/StrategicFocus.png";
import Second from "../../assests/WhyUs/LongOnlyApproch.png";
import Third from "../../assests/WhyUs/Seamless.png";
import Fourth from "../../assests/WhyUs/Consistent.png";
import Fifth from "../../assests/WhyUs/Risk.png";
import Six from "../../assests/WhyUs/Automated.png";

const data = [
 
  {
    heading: "Commitment to Transparency",
    desc: "Our client portal provides real-time portfolio access and performance updates",
    imgUrl: Six,
    tag: "",
  },

  {
    heading: "Adaptive Rebalancing",
    desc: "Our algorithms adapt to changing market conditions, with weekly rebalancing to optimize portfolios.",
    imgUrl: Fifth,
    tag: "",
  },
  {
    heading: "Innovative Execution",
    desc: "Fully automated execution model to minimize biases and enhance precision",
    imgUrl: Six,
    tag: "",
  },
  {
    heading: "Addressing Market Challenges",
    desc: "We eliminate inefficiencies like high fees and underperformance by employing smart beta strategies and optimized risk management.",
    imgUrl: Six,
    tag: "",
  },
  {
    heading: "Quantitative Approach",
    desc: "Using momentum, strength, and relative value indicators, we consistently identify high-quality stocks to outperform benchmarks",
    imgUrl: First,
    tag: "",
  },
  
];

const Philosophy = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div className="flex flex-col md:flex-row bg-[#243444]" id="philosophy">
      <div className="w-full pt-12 pb-[30px]  md:py-20 px-4 md:px-36">
        <div className="hiddenAnimation text-center  text-white text-[30px] md:text-[34px] md:leading-[66px] font-bold font-sans">
          Our Investment Philosophy
        </div>
        <div className="hiddenAnimation text-center lg:max-w-[1200px] lg:mx-auto text-white text-sm leading-[24px] md:text-lg font-poppins font-normal opacity-60 mt-6">
          Second Street Partners delivers superior, long-term investment results
          by leveraging momentum-based and trend-following strategies in both US
          and Indian equities. Our adaptive, data-driven approach aims to
          maximize returns while minimizing risks.
        </div>
        {/* <div className="hiddenAnimation text-center md:text-start text-white text-sm leading-[24px] md:text-lg font-poppins font-normal opacity-60 mt-10">
          Our approach emphasizes long-term growth through a rigorous,
          data-driven methodology that minimizes biases and maximizes return
          potential. Differentiating ourselves through tailored solutions and
          transparency, we prioritize a secure and compliant investment
          environment. Our commitment to innovation is evident in our fully
          automated execution model, ensuring a path to financial success for
          our clients.
        </div> */}
        <div className="grid grid-cols-1 gap-y-5 w-full md:grid-cols-6 md:gap-5 mt-12">
          {data.map((ele, i) => {
            return (
              <div
                className={`${ i=== 3 || i===4 ?'col-span-3' : 'col-span-2'} hiddenAnimation hover:scale-[1.04] flex flex-col md:flex-row bg-white px-6 md:px-4 py-8 md:py-8 rounded-[12px]`}
                key={i}
              >
                {/* <div>
                  <img src={ele.imgUrl} alt={ele.tag} width={120} />
                </div> */}
                <div className="md:ml-6 mt-4 md:mt-0">
                  <div className="text-center md:text-left text-xl md:text-xl font-bold text-[#211f54] font-sans">
                    {ele.heading}
                  </div>
                  <div className="text-center md:text-left text-sm md:text-base text-[#211f54]/90 font-poppins mt-3">
                    {ele.desc}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="hiddenAnimation text-center md:w-[20%] md:mx-auto mt-12 px-8 md:px-0">
          <ConnectNow />
        </div>
      </div>

      
    </div>
  );
};

export default Philosophy;
