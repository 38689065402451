import React, { useEffect } from "react";
import animation from "../../common/animation";
import styles from "./HeroSection.module.css";
const HeroSection = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  return (
    <div className={`${styles.backgroundHero} `} id="home">
      <div className={styles.backgroundImage}>
        <div className={styles.overlay}></div>
        <div className={`py-36 md:py-48 px-2 md:px-36 ${styles.textContent}`}>
          <div className="md:flex flex-col hidden">
            <div className="hiddenAnimation text-white md:text-[74px]  md:leading-[80px] font-sans font-bold">
              Unlocking{" "}
            </div>
            <div className="hiddenAnimation text-white text-[74px] leading-[80px] font-sans font-bold">
              Alpha through{" "}
            </div>
            <div className="hiddenAnimation text-white text-[74px] leading-[80px] font-sans font-bold">
              Momentum-based Investing
            </div>
            <div className="hiddenAnimation w-[50%] text-[#eff0f6] text-lg font-poppins mt-8">
              A concentrated approach to trend investing, balancing risk and
              reward for optimal performance..
            </div>
            <div className="hiddenAnimation flex flex-row items-center mt-8">
              <a
                href="https://cal.com/secondstreet"
                target="_blank"
                rel="noreferrer noopener"
                className="px-12 py-3  bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[8px] hover:scale-105 transition-all duration-150 ease-linear cursor-pointer"
              >
                Talk to us
              </a>
              <a href="#strategies">
                <div className="md:px-12 py-3 ml-6 text-xl font-medium text-white border border-[#ffffff] rounded-[8px] hover:bg-[#ffffff] hover:text-[#000000] hover:scale-105 transition-all duration-150 ease-linear cursor-pointer">
                  Our Strategy
                </div>
              </a>
            </div>
          </div>
          <div className="px-3 hiddenAnimation md:hidden text-white text-[26px] leading-[37px] text-center font-bold font-sans">
            Unlocking Alpha Through Momentum-Based Investing
            <div className="hiddenAnimation w-full text-center text-sm leading-[28px] text-[#eff0f6] font-normal font-poppins mt-8 ">
              A concentrated approach to trend investing, balancing risk and
              reward for optimal performance.
            </div>
            <div className="hiddenAnimation flex flex-col px-4 space-y-4 md:space-y-0 md:space-x-6 md:flex-row justify-center mt-8">
              <a
                href="https://cal.com/secondstreet"
                target="_blank"
                rel="noreferrer noopener"
                className="px-6 md:px-12 py-3 bg-[#ffc907] text-[#000000] font-bold text-xl rounded-[4px] hover:scale-105 cursor-pointer"
              >
                Talk to us
              </a>
              <a href="#strategies">
                <div className="px-6 md:px-12 py-3  text-xl font-medium text-white border border-[#ffffff] rounded-[4px] hover:bg-[#ffffff] hover:text-[#000000] hover:scale-105 cursor-pointer">
                  Our Strategy
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
